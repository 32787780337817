.css-xruqei {
  margin-bottom: 10px !important;
}
ion-content {
  --background: #181919;
}
ion-button {
  --background: #181919;
  --border-radius: 10px;
  border-radius: 20px;
  --padding: 10px;
  border: 6px solid black;
  --color: #fff;
  --padding-end: 10px;
  --padding-start: 10px;
}
